import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../shared/services/http.service';
import { SiteId } from '../../site-detail/models/Site';
import { VideoCall } from '../models/video-call';
import { VideoCallGateway } from './VideoCallGateway';

@Injectable()
export class HttpVideoCallGateway implements VideoCallGateway {
    constructor(private httpService: HttpService) {}

    startVideoCall(siteId: SiteId): Observable<VideoCall> {
        return this.httpService.post(`/video-call/${siteId}`, {}).pipe(map((videoCall) => ({ ...videoCall, siteId })));
    }

    stopVideoCall(siteId: SiteId): Observable<void> {
        return this.httpService.delete(`/video-call/${siteId}`);
    }
}
